// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    welcome: path(ROOTS_DASHBOARD, '/welcome'),
    pricePush: path(ROOTS_DASHBOARD, '/price-push'),
    userManagement: path(ROOTS_DASHBOARD, '/user-management'),
  },
  masterConfiguration: {
    ftpConfiguration: path(ROOTS_DASHBOARD, '/master-configuration/ftp-configuration'),
    root: path(ROOTS_DASHBOARD, '/surveys/assigned-sites'),
  },
  cancoStore: {
    root: path(ROOTS_DASHBOARD, '/master-configuration/canco-store'),
    new: path(ROOTS_DASHBOARD, '/master-configuration/canco-store/store/new'),
    edit: (name) => path(ROOTS_DASHBOARD, `/master-configuration/canco-store/store/${name}/edit`),
  },
  thirdPartySites: {
    root: path(ROOTS_DASHBOARD, '/master-configuration/third-party-sites'),
    new: path(ROOTS_DASHBOARD, '/master-configuration/third-party-sites/new'),
    edit: (name) => path(ROOTS_DASHBOARD, `/master-configuration/third-party-sites/${name}/edit`),
  },
  thirdPartyOrganisations: {
    root: path(ROOTS_DASHBOARD, '/master-configuration/third-party-organisations'),
    new: path(ROOTS_DASHBOARD, '/master-configuration/third-party-organisations/new'),
    edit: (name) => path(ROOTS_DASHBOARD, `/master-configuration/third-party-organisations/${name}/edit`),
  },
  fuelTypes: {
    root: path(ROOTS_DASHBOARD, '/master-configuration/fuel-types'),
    new: path(ROOTS_DASHBOARD, '/master-configuration/fuel-types/new'),
    edit: (name) => path(ROOTS_DASHBOARD, `/master-configuration/fuel-types/${name}/edit`),
  },
  ftpConfiguration: {
    root: path(ROOTS_DASHBOARD, '/master-configuration/ftp-configuration'),
    new: path(ROOTS_DASHBOARD, '/master-configuration/ftp-configuration/new'),
    edit: (name) => path(ROOTS_DASHBOARD, `/master-configuration/ftp-configuration/${name}/edit`),
  },
  surveys: {
    root: path(ROOTS_DASHBOARD, '/surveys/surveys-list'),
    detail: (id) => path(ROOTS_DASHBOARD, `/surveys/surveys-list/${id}`),
    assignedSites: (id) => path(ROOTS_DASHBOARD, `/surveys/surveys-list/assigned-sites/${id}`),
  },
  assignedSites: {
    root: path(ROOTS_DASHBOARD, '/surveys/assigned-sites'),
    new: path(ROOTS_DASHBOARD, '/surveys/assigned-sites/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/surveys/assigned-sites/${id}/edit`),
  },
  surveysList: {
    root: path(ROOTS_DASHBOARD, '/surveys/surveys-list'),
    new: path(ROOTS_DASHBOARD, '/surveys/surveys-list/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/surveys/surveys-list/${id}/edit`),
  },
  userManagement: {
    root: path(ROOTS_DASHBOARD, '/user-management'),
    new: path(ROOTS_DASHBOARD, '/user-management/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/user-management/${id}/edit`),
  },
  pricePush: {
    root: path(ROOTS_DASHBOARD, '/price-push'),
    new: path(ROOTS_DASHBOARD, '/price-push/new'),
    edit: (name) => path(ROOTS_DASHBOARD, `/price-push/${name}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/price-push/${id}`),
  },
  roadTripConfig: {
    root: path(ROOTS_DASHBOARD, '/road-trip-configuration'),
  },
  storeRewards: {
    root: path(ROOTS_DASHBOARD, '/store-rewards'),
  },
  rewardsGrandPrize: {
    root: path(ROOTS_DASHBOARD, '/rewards-and-grand-prize'),
  },
  usersParticipated: {
    root: path(ROOTS_DASHBOARD, '/users-participated'),
  },
  generateReport: {
    root: path(ROOTS_DASHBOARD, '/generate-report'),
  },
};
