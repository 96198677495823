import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
import { MODULE } from '../../../utils/apiUrl';
import { adminApi } from '../../../utils/axios';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './NavConfig';
import NavbarDocs from './NavbarDocs';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar, moduleAccess, userRole }) {
  const [allModulesData, setAllModulesData] = useState([]);
  const [filteredModule, setFilteredModule] = useState([]);

  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // useEffect(() => {
  //   if (moduleAccess) {
  //     getModuleData();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [moduleAccess]);

  // // Getting module data
  // const getModuleData = async () => {
  //   try {
  //     const response = await adminApi.get(MODULE, {
  //       params: {
  //         limit: 1000,
  //       },
  //     });
  //     const moduleListTemp = response.data.results.map((module) => module);
  //     setAllModulesData(moduleListTemp);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // Add Module Routes to data
  // useEffect(() => {
  //   if (allModulesData.length > 0) {
  //     // Syncing Parent module url
  //     const syncModuleUrl = allModulesData.map((item) => {
  //       const isPresent = (val) => {
  //         return val.toLowerCase() === item.name.toLowerCase() ? item.submodules : false;
  //       };

  //       const urlData = meringModulePathHandler(navConfig[0].items, isPresent);

  //       return { ...item, path: urlData.path, icon: urlData.icon, submodules: urlData.submodules };
  //     });

  //     // Filtering arr1 based on arr2

  //     const accessibleModule = syncModuleUrl.filter((obj1) => {
  //       const isPresent = moduleAccess.find((obj2) => obj2.module === obj1.id);
  //       if (isPresent) {
  //         filterChildren(obj1, isPresent.submodules);
  //         return true;
  //       }
  //       return false;
  //     });

  //     setFilteredModule(
  //       accessibleModule.map((item) => ({
  //         title: item.name,
  //         icon: item.icon,
  //         path: item.path,
  //         children:
  //           item.submodules.length > 0
  //             ? item.submodules.map((obj) => {
  //                 return {
  //                   title: obj.names,
  //                   path: obj.path,
  //                 };
  //               })
  //             : null,
  //       }))
  //     );
  //   }
  // }, [allModulesData, setFilteredModule]);

  // function meringModulePathHandler(arr, condition) {
  //   // Iterate over the array
  //   for (let i = 0; i < arr.length; i += 1) {
  //     // Check if the condition is met for the current element
  //     if (condition(arr[i].title)) {
  //       // Syncing Sub Module Url
  //       const subModuleData = condition(arr[i].title);
  //       // console.log(
  //       //   'sub mod data',
  //       //   subModuleData?
  //       // );
  //       let syncSubModuleUrl = [];
  //       if (subModuleData.length > 0) {
  //         syncSubModuleUrl = subModuleData?.map((item) => {
  //           const isPresent = (val) => {
  //             return val.toLowerCase() === item.names.toLowerCase();
  //           };
  //           const subUrlData = meringSubModulePathHandler(arr[i].children, isPresent);
  //           return { ...item, path: subUrlData.path };
  //         });
  //       }
  //       // console.log('synced sub module data', syncSubModuleUrl);

  //       return {
  //         path: arr[i].path,
  //         icon: arr[i].icon,
  //         submodules: syncSubModuleUrl,
  //       };
  //     }
  //   }
  //   // Return the value if the condition is never met
  //   return false;
  // }

  // function meringSubModulePathHandler(arr, condition) {
  //   // Iterate over the array
  //   for (let i = 0; i < arr.length; i += 1) {
  //     // Check if the condition is met for the current element
  //     if (condition(arr[i].title)) {
  //       // Return the value if the condition is true
  //       return {
  //         path: arr[i].path,
  //       };
  //     }
  //   }
  //   // Return the value if the condition is never met
  //   return false;
  // }

  // function filterChildren(parent, reference) {
  //   if (!parent.submodules || parent.submodules.length === 0) {
  //     return;
  //   }

  //   parent.submodules = parent.submodules.filter((child) => reference.some((refChild) => refChild === child.id));

  //   parent.submodules.forEach((child) => filterChildren(child, reference));
  // }

  

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Logo />

          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />

      {/* {!isCollapse && <NavbarDocs />} */}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
