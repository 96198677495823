import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';
import moment from 'moment';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default function RHFDatePicker({ name, disabled, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            {...field}
            renderInput={(props) => <TextField {...props} />}
            fullWidth
            error={!!error}
            helperText={error?.message}
            disabled={disabled}
            {...other}
          />
        </LocalizationProvider>
      )}
    />
  );
}
