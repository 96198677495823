import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Typography, useTheme, Switch, FormControlLabel } from '@mui/material';
import { useSnackbar } from 'notistack';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';

// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import AccountPopover from './AccountPopover';
import { adminApi } from '../../../utils/axios';
import { ROAD_TRIP } from '../../../utils/apiUrl';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const [currentEventYear, setCurrentEventYear] = useState(null);
  const [locValidation, setLocValidation] = useState(false);
  const [currentEventId, setCurrentEventId] = useState(null);
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getRoadTripData();
  }, []);

  const getRoadTripData = async () => {
    try {
      const response = await adminApi.get(ROAD_TRIP);
      // console.log('road trip', response?.data);
      const activeEvent = response?.data;
      setCurrentEventYear(activeEvent[0]?.event_year);
      setLocValidation(activeEvent[0]?.is_prod_deploy);
      setCurrentEventId(activeEvent[0]?.id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggle = async (event) => {
    setLocValidation(event.target.checked);
    const requestData = {
      is_prod_deploy: event.target.checked,
    };
    try {
      const response = await adminApi.patch(`${ROAD_TRIP}${currentEventId}/`, requestData);
      enqueueSnackbar('Update success!');
      getRoadTripData();
    } catch (error) {
      enqueueSnackbar('Something went wrong.', {
        variant: 'error',
      });
    }
  };

  // console.log('loc valid', locValidation);

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 3 }}>
          <FormControlLabel
            control={
              <Switch
                checked={locValidation}
                onChange={handleToggle}
                sx={{
                  '& .MuiSwitch-thumb': {
                    backgroundColor: '#F57026', // Customize the thumb color here
                  },
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={<Typography style={{ color: 'black' }}>Location Validation</Typography>}
          />
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ border: `1px solid ${theme.palette.divider}`, borderRadius: 1.5, p: 1 }}
          >
            <Iconify icon="solar:earth-bold-duotone" height={22} width={22} color="text.secondary" />
            <Typography variant="subtitle1" color="text.secondary">
              Road Trip - {currentEventYear}
            </Typography>
          </Stack>

          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
