// export const ADMIN = 'https://api.survey.dev.cancopetroleum.ca/api/admin';
export const ADMIN = process.env.REACT_APP_BASE_URL;

export const LOGIN = `${ADMIN}/admin/accounts/login/`;
export const USERS = `${ADMIN}/admin/accounts/users/`;
export const STORE = `${ADMIN}/store/`;
export const MODULE = `${ADMIN}/module/`;
export const THIRDPARTY_ORG = `${ADMIN}/thirdparty/org/`;
export const THIRDPARTY_SITE = `${ADMIN}/thirdparty/site/`;
export const FUEL_TYPES = `${ADMIN}/fuel_type/`;
export const FTP_CONFIGURATIONS = `${ADMIN}/ftp_configuration/`;
export const SURVEY = `${ADMIN}/survey/`;

export const CITY = `${ADMIN}/city/`;
export const PROVINCE = `${ADMIN}/province/`;
export const PRICE_PUSH = `${ADMIN}/price_request/`;
// NEW API
export const ROAD_TRIP = `${ADMIN}/canco100/canco100roadtrip/`;
export const STORE_REWARDS = `${ADMIN}/canco100/storewisereward/`;
export const USERS_PARTICIPATED = `${ADMIN}/canco100/winnerusers/`;
export const REWARDS_GRAND_PRIZE = `${ADMIN}/canco100/participated-users/`;
export const GENERATE_REPORT = `${ADMIN}/canco100/grand-prize-reward/create_excel/`;
export const GENERATE_REPORT_STORE_REWARDS = `${ADMIN}/canco100/storewisereward/create_excel/`;
export const GENERATE_REPORT_REWARDS_GRAND_PRIZE = `${ADMIN}/canco100/participated-users/create_excel/`;
export const GENERATE_REPORT_PARTICIPATED_USER = `${ADMIN}/canco100/winnerusers/create_excel/`;
