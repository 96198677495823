// components
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

import { PATH_DASHBOARD } from '../../../routes/paths';

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_menu_item'),
};

const navConfig = [
  {
    subheader: 'management',
    items: [
      {
        title: 'Road Trip Configuration',
        path: PATH_DASHBOARD.roadTripConfig.root,
        icon: <Iconify icon={'codicon:settings'} width={32} height={32} />,
      },
      {
        title: 'Store Rewards',
        path: PATH_DASHBOARD.storeRewards.root,
        icon: <Iconify icon={'mdi:prize'} width={32} height={32} />,
      },
      {
        title: 'Rewards & Grand Prize',
        path: PATH_DASHBOARD.rewardsGrandPrize.root,
        icon: <Iconify icon={'mdi:prize'} width={32} height={32} />,
      },
      { title: 'Users Participated', path: PATH_DASHBOARD.usersParticipated.root, icon: ICONS.user },
      {
        title: 'Generate Report',
        path: PATH_DASHBOARD.generateReport.root,
        icon: <Iconify icon={'carbon:report'} width={32} height={32} />,
      },
    ],
  },
];

export default navConfig;
